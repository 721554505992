<template>
  <div class="playback">
    <!-- 左侧媒体展示 -->
    <div class="left_media">
      <!-- 视频地图切换 -->
      <div class="toggle-modal">
        <div v-if="videos.length > 0">
          <video class="video"
                 v-for="item in videos"
                 controls
                 :key="item.id"
                 :src="item.path"></video>
        </div>
        <div class="no_video"
             v-else>该轨迹无视频</div>

        <!-- 照片 -->
        <div class="route_photo">航线照片</div>
        <div class="route_photo_list"
             v-if="trackImglist.length > 0">
          <viewer :images="trackImglist">
            <div v-for="img in trackImglist"
                 :key="img.id">
              <img :src="img.path" />
            </div>
          </viewer>
        </div>
        <div v-else
             class="no_photo">该轨迹无照片</div>
      </div>
    </div>
    <div style="height: 100%; width: 11.76rem">
      <Map ref="map_0"
           :config="bigMapConfig"
           :imgList="trackImglist"
           :mapFlyingParams="mapFlyingParams" />
      <div class="chart"
           :class="!windows.showChart ? '' : 'show'">
        <div class="inner">
          <div class="btn"
               @click="triggleWindow('chart')">
            <Icon size="24"
                  color="#fff"
                  type="ios-arrow-forward" />
          </div>
          <div class="chart-inner">
            <div class="header">
              <span>
                <pre>高度 (m)</pre>
              </span>
              <span>
                <pre>水平速度 (m/s)</pre>
              </span>
            </div>
            <div class="center">
              <div id="chart"></div>
              <div class="signals">
                <div class="signal-item">
                  <img :src="icons.wire"
                       alt />
                  <span>
                    <pre>高度 (m)</pre>
                  </span>
                  <span>{{ dataBoadInfo.DroneAltitude }}m</span>
                </div>
                <div class="signal-item">
                  <img :src="icons._line"
                       alt />
                  <span>
                    <pre>水平速度 (m/s)</pre>
                  </span>
                  <span>{{ dataBoadInfo.horizontal_velocity }}m/s</span>
                </div>
              </div>
            </div>
          </div>
          <div class="bar">
            <div class="icon"
                 @click.stop="togglePlay">
              <img :src="controlOptions.playingStatus ? icons.playBegin : icons.pause" />
            </div>
            <div class="line-bar">
              <Slider v-model="controlOptions.slider"
                      show-tip="never"
                      @on-change="sliderInput"
                      :max="controlOptions.allPlayingCounts"
                      :step="controlOptions.sliderStep"></Slider>
            </div>
            <div class="time-left">
              <pre>{{ controlOptions.currentTime | formatPlayTime }} / {{
                  drawAllTime | formatPlayTime
                }}</pre>
            </div>
            <!-- 倍速按钮 -->
            <div class="speed">
              <Poptip trigger="hover">
                <div slot="content"
                     class="pop-list">
                  <Button v-for="speed in speedList"
                          :key="speed.value"
                          @click.stop="setSpeed(speed.value)">{{ speed.value }}X</Button>
                </div>
                <Button>{{ controlOptions.currentSpeed }}X</Button>
              </Poptip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dash-board">
      <div class="user_info">
        <div class="user_info_item head_icon">
          <img :src="info.head_icon" />
          <div>
            <span>{{ info.name }}</span>
            <p>{{ info.dronemodel }}</p>
          </div>
        </div>
        <!-- <div class="user_info_item">
          <img :src="icons.drone">{{ info.dronemodel }}
        </div> -->
        <div class="user_info_item"
             style="display: flex; justifyContent: space-between">
          <div><img :src="icons.altitude" />{{ info.fly_distance | formatMiles }}</div>
          <div><img :src="icons.time" />{{ info.fly_time | formatSecondsInChinese }}</div>
          <div>
            <img :src="icons.date" />{{ info.create_time | getFirstHalfPartTime }}
          </div>
        </div>
      </div>

      <div class="item title">仪表盘</div>
      <div class="item">
        <img :src="icons.speed" />
        <span>
          <p>{{ dataBoadInfo.vertical_speed | formatSpeed }}</p>
          <span>垂直速度</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.yaw" />
        <span>
          <p>{{ dataBoadInfo.DroneYaw | formatAngle }}</p>
          <span>航向角</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.roll" />
        <span>
          <p>{{ dataBoadInfo.DroneRoll | formatAngle }}</p>
          <span>横滚角</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.angle" />
        <span>
          <p>{{ dataBoadInfo.DronePitch | formatAngle }}</p>
          <span>俯仰角</span>
        </span>
      </div>
      <div class="item large">
        <img :src="icons.hight" />
        <span>
          <p>
            {{ (Math.floor(dataBoadInfo.DroneAltitude * 100) / 100) | formatMiles }}
          </p>
          <span>相对于起飞点的高度</span>
        </span>
      </div>
      <div class="item title">基本信息</div>
      <div class="item">
        <img :src="icons.signal" />
        <span>
          <p>{{ dataBoadInfo.remote | formatPercent }}</p>
          <span>遥控信号</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.signal" />
        <span>
          <p>{{ dataBoadInfo.figure_pass | formatPercent }}</p>
          <span>图传信号</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.energypatrol" />
        <span>
          <p>{{ dataBoadInfo.remote_power | formatPercent }}</p>
          <span>遥控电量</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.dianliang" />
        <span>
          <p>{{ dataBoadInfo.power | formatPercent }}</p>
          <span>飞机电量</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.temperature" />
        <span>
          <p>{{ dataBoadInfo.temperature | formatTemperatrue }}</p>
          <span>电池温度</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.voltage" />
        <span>
          <p>{{ dataBoadInfo.voltage | formatVoltage }}</p>
          <span>电压</span>
        </span>
      </div>
      <div class="item title">GNSS</div>
      <div class="item">
        <img :src="icons.satellite" />
        <span>
          <p>{{ dataBoadInfo.satellite_signal }}</p>
          <span>卫星数量</span>
        </span>
      </div>
      <div class="item">
        <img :src="icons.highta" />
        <span>
          <p>
            {{ (Math.floor(dataBoadInfo.highly * 100) / 100) | formatMiles }}
          </p>
          <span>起飞点海拔</span>
        </span>
      </div>
      <div class="item large">
        <img :src="icons.site" />
        <span>
          <p>经度 {{ dataBoadInfo.DroneLongitude | formatPosition }}</p>
          <p>纬度 {{ dataBoadInfo.DroneLatitude | formatPosition }}</p>
        </span>
      </div>
    </div>
    <Spin size="large"
          fix
          v-if="spinShow"></Spin>
  </div>
</template>

<script>
import _ from 'lodash';
import date from '@/assets/img/statistics/date.png';
import drone from '@/assets/img/statistics/drone.png';
import altitude from '@/assets/img/statistics/altitude.png';
import time from '@/assets/img/statistics/time.png';
import place from '@/assets/img/statistics/place.png';
import speed from '@/assets/img/statistics/speed.png';
import hight from '@/assets/img/statistics/hight.png';
import highta from '@/assets/img/statistics/highta.png';
import yaw from '@/assets/img/statistics/yaw.png';
import angle from '@/assets/img/statistics/angle.png';
import roll from '@/assets/img/statistics/roll.png';
import level from '@/assets/img/statistics/level.png';
import signal from '@/assets/img/statistics/signal.png';
import image from '@/assets/img/statistics/image.png';
import energypatrol from '@/assets/img/statistics/energypatrol.png';
import dianliang from '@/assets/img/statistics/dianliang.png';
import temperature from '@/assets/img/statistics/temperature.png';
import voltage from '@/assets/img/statistics/voltage.png';
import satellite from '@/assets/img/statistics/satellite.png';
import site from '@/assets/img/statistics/site.png';
import pause from '@/assets/img/statistics/pause.png';
import begin from '@/assets/img/statistics/begin.png';
import _line from '@/assets/img/statistics/_line.png';
import noVideo from '@/assets/img/statistics/not_live.png';
import wire from '@/assets/img/statistics/wire.png';
import Map from '@/components/statistics/commonComponents/map.vue';
import folderModal from '@/components/statistics/commonComponents/folderModal.vue';
import Api from '@/utils/api.js';
import filterMixin from '@/utils/filter.mixin.js';
// import Video from '@/assets/img/video/video.mp4';
import playBegin from '@/assets/img/statistics/play-begin.png';

export default {
  name: 'trajectoryPlayback',
  mixins: [filterMixin],
  components: { Map, folderModal },
  data() {
    return {
      bigMapConfig: {
        toolsMovingDistance: 4,
      },
      icons: {
        date,
        drone,
        altitude,
        time,
        place,
        speed,
        hight,
        highta,
        yaw,
        angle,
        roll,
        level,
        signal,
        image,
        energypatrol,
        dianliang,
        temperature,
        voltage,
        satellite,
        site,
        pause,
        begin,
        _line,
        wire,
        playBegin,
        noVideo,
      },
      spinShow: true,
      windows: {
        showChart: false,
      },
      info: {
        head_icon: '',
        name: '',
        create_time: '',
        DRONETYPE: '',
        fly_distance: '',
        fly_time: '',
        address: '',
      },
      flightPathData: [],
      dataBoadInfo: {
        vertical_speed: 0,
        highly: 0,
        DronePitch: 0,
        DroneRoll: 0,
        remote: 0,
        figure_pass: 0,
        remote_power: 0,
        power: 0,
        temperature: 0,
        voltage: 0,
        DroneLongitude: 0,
        DroneLatitude: 0,
        DroneAltitude: 0,
      },
      controlOptions: {
        playingStatus: false, // 当前是否开始绘制
        currentTime: 0.0, // 当前已经过去的播放时长
        currentSpeed: 1, // 当前速率
        allPlayingCounts: 0, // 总循环次数
        slider: 0, // 进度条值
        sliderStep: 1,
        speedBase: 1, // 默认2s播放间隔
      },
      speedList: [
        {
          label: 8,
          value: 8,
        },
        {
          label: 4,
          value: 4,
        },
        {
          label: 2,
          value: 2,
        },
        {
          label: 1,
          value: 1,
        },
      ],
      mapFlyingParams: {
        pathArr: [],
        // speed: 10,
        speedChanged: false,
        playingStatus: false,
        allPlayingCounts: 0,
        curIndex: 0,
      },
      trackImglist: [], // 飞行中拍摄的图片
      videos: [], //飞行中拍摄的视频
    };
  },
  computed: {
    // 显示在进度条后面的总的播放时间
    drawAllTime() {
      return this.controlOptions.allPlayingCounts;
    },
    // 当前定时器的时间间隔 毫秒
    drawInterval() {
      return (
        (this.controlOptions.speedBase / this.controlOptions.currentSpeed) *
        1000
      );
    },
  },

  mounted() {
    this.fetchData();
  },
  beforeDestroy() {
    // 移除chart的resize的事件
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    // 设置轨迹绘制的速度 km/h 这个速度是真的坑
    setMapFlyingPathSpeed(curIndex) {
      // 每次定时器取数组中的2个元素当做这次要走的路程
      let needMoveArr = this.mapFlyingParams.pathArr.slice(
        curIndex,
        curIndex + 2
      );
      // 最后一个了 不用设置速度了
      if (needMoveArr.length < 2) return;
      let allLeftMiles = AMap.GeometryUtil.distance(
        _.cloneDeep(needMoveArr[0]),
        _.cloneDeep(needMoveArr[1])
      );
      let allLeftTime =
        this.controlOptions.speedBase / this.controlOptions.currentSpeed;
      let v = parseFloat(allLeftMiles / 1000) / parseFloat(allLeftTime / 3600);
      console.log('此次需要移动的距离为:', allLeftMiles);
      console.log('此次移动时间为:', allLeftTime);
      this.mapFlyingParams.speed = v;
      console.log(`当前地图轨迹绘制速度为${v}km/h`);
    },
    // 初始化地图那边需要的一些绘制数据
    initMapPath() {
      this.mapFlyingParams.pathArr = this.flightPathData.map((ele) => {
        return [parseFloat(ele.DroneLongitude), parseFloat(ele.DroneLatitude)];
      });
      this.mapFlyingParams.allPlayingCounts = this.flightPathData.length;
      this.setMapFlyingPathSpeed(0);
    },
    // 定时器中的更新地图信息 主要是curIndex
    updateMap(curIndex) {
      this.mapFlyingParams.curIndex = curIndex;
      this.setMapFlyingPathSpeed(curIndex);
      if (curIndex >= this.controlOptions.allPlayingCounts) {
        // 结束了
        setTimeout(() => {
          this.mapFlyingParams.playingStatus = false;
          this.mapFlyingParams.curIndex = 0;
        }, 1000);
      }
    },

    // 更新进度条
    updateSliderBar(curIndex) {
      this.controlOptions.slider = curIndex;
      if (curIndex === 0) {
        return;
      }
      this.controlOptions.currentTime += 1;
      //   this.controlOptions.currentTime += this.controlOptions.speedBase;
      if (curIndex >= this.controlOptions.allPlayingCounts) {
        setTimeout(() => {
          this.controlOptions.slider = 0;
          this.controlOptions.playingStatus = false;
        }, 1000);
        setTimeout(() => {
          this.controlOptions.currentTime = 0;
        }, 1000);
      }
    },
    // 滑动slider
    sliderInput(e) {
      // 先暂停定时器,赋值this.allLeftCounts,再继续计时器
      if (this.controlOptions.playingStatus) {
        this.togglePlay();
        this.$nextTick(() => {
          this.controlOptions.currentTime = e;
          this.allLeftCounts = this.controlOptions.allPlayingCounts - e;
          this.togglePlay();
        });
      } else {
        this.controlOptions.currentTime = e;
        this.allLeftCounts = this.controlOptions.allPlayingCounts - e;
      }
    },
    // 更新dashboard面板
    updateDashBoard(curIndex) {
      this.dataBoadInfo = this.flightPathData[curIndex];
    },
    // 绘制轨迹核心函数
    // 轨迹绘制的核心是 :
    // 全部的播放次数
    // 从接口拿到的数据总个数
    // 每次定时器固定减少1
    // 速率控制定时器的时间间隔
    // 每次更新同步 面板 表哥 地图的数据
    draw(start = true) {
      if (start) {
        // 开始
        // 执行总次数
        this.allLeftCounts =
          (this.allLeftCounts !== 0 && this.allLeftCounts) ||
          this.controlOptions.allPlayingCounts;
        console.log(`还剩余${this.allLeftCounts}次需要执行绘制`);
        let _draw = () => {
          if (this.allLeftCounts <= 0) {
            console.log('draw ended:……');
            this.timer && clearInterval(this.timer);
            this.updateSliderBar(this.controlOptions.allPlayingCounts);
            this.updateMap(this.controlOptions.allPlayingCounts);
            return;
          }
          let curIndex =
            this.controlOptions.allPlayingCounts - this.allLeftCounts;
          console.log(`draw called:……当前次数为${curIndex}`);
          console.log(
            `draw called:……当前选择绘制速度比率为${this.controlOptions.currentSpeed}`
          );
          // draw to do
          this.updateDashBoard(curIndex);
          this.updateSliderBar(curIndex);
          this.updateMap(curIndex);
          --this.allLeftCounts;
        };
        _draw();
        this.timer = setInterval(() => {
          console.log(`${this.drawInterval}ms绘制轨迹一次……`);
          _draw();
        }, this.drawInterval);
      } else {
        // 暂停
        this.timer && clearInterval(this.timer);
      }
    },
    // 播放/暂停
    togglePlay() {
      this.controlOptions.playingStatus = !this.controlOptions.playingStatus;
      this.mapFlyingParams.playingStatus = !this.mapFlyingParams.playingStatus;
      this.draw(this.controlOptions.playingStatus);
    },
    // 更改倍速
    setSpeed(val) {
      this.controlOptions.currentSpeed = val;
      if (this.controlOptions.playingStatus) {
        // 播放过程中切换速度
        this.togglePlay();
        this.$nextTick(() => {
          this.togglePlay();
        });
      }
    },
    // 初始化总的定时器执行次数
    initDataForDraw() {
      console.log('初始化视频url', this.videos);
      this.controlOptions.allPlayingCounts = this.flightPathData.length;
    },
    // 拉取轨迹数据
    fetchData() {
      let track_id = this.$route.query.track_id;
      this.$post(Api.getTrackDetail(), { track_id: track_id })
        .then((res) => {
          if (res.code == 1) {
            this.info = res.data;
            this.flightPathData = res.data.data;
            this.trackImglist = res.data.play_back_results;
            this.videos = res.data.vedios;
            this.initMapPath();
            this.dataBoadInfo = this.flightPathData[0];
            console.log(this.flightPathData);
            this.initDataForDraw();
            this.initChart();
          } else {
            this.$Message.error(res.msg_customer);
            // this.$notice.error({
            //   title: '飞行轨迹接口异常,返回无数据1',
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '飞行轨迹接口异常,返回无数据2',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },

    // 切换大地图和小地图
    triggleWindow(windowType) {
      if (windowType === 'chart') {
        this.windows.showChart = !this.windows.showChart;
      }
    },
    // 初始化chart
    initChart() {
      let chartTime = this.flightPathData.map((ele) => {
        return parseFloat(ele.time);
      });
      let chartDataDroneAltitude = this.flightPathData.map((ele) => {
        return parseFloat(ele.DroneAltitude);
      });
      let chartDatahorizontal_velocity = this.flightPathData.map((ele) => {
        return parseFloat(ele.horizontal_velocity);
      });

      this.myChart = this.$echarts.init(document.getElementById('chart'));
      let option = {
        grid: {
          top: 20,
          bottom: 10,
          left: '5%',
          right: '5%',
        },
        xAxis: {
          show: false,
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          splitLine: {
            show: false,
            interval: 0,
            lineStyle: {
              color: '#F8F8F8',
            },
          },
          axisLabel: {
            margin: 24,
            interval: 1,
          },
          data: chartTime,
        },
        yAxis: [
          {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#F8F8F8',
              },
            },
            axisLabel: {
              color: '#fff',
            },
          },
          {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#fff',
            },
          },
        ],
        series: [
          {
            name: 'height',
            type: 'line',
            smooth: true,
            showSymbol: false,
            clip: true,
            lineStyle: {
              color: '#B2E7FF',
              width: 2,
              smooth: true,
              smoothMonotone: 'x',
            },
            data: chartDataDroneAltitude,
          },
          {
            name: 'speed',
            type: 'line',
            smooth: true,
            showSymbol: false,
            clip: true,
            lineStyle: {
              color: '#FF8A78',
              width: 2,
              smooth: true,
              smoothMonotone: 'x',
            },
            yAxisIndex: 1,
            data: chartDatahorizontal_velocity,
          },
        ],
      };
      this.myChart.setOption(option);
      let _this = this;
      this.resize = _.debounce(function () {
        _this.myChart.resize();
      }, 1000);
      window.addEventListener('resize', this.resize);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
video {
  object-fit: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  display: none;
}
.playback {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  display: flex;
  .left_media {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 2.8rem;
    // height: 100%;
    height: 8.87rem;
    // background-color: #fff;
    background-color: #28292e; // $xf_hei5
    z-index: 50;
    overflow-y: auto;
    .toggle-modal {
      flex: 1;
      height: 100%;
    }
    .no_video {
      width: 2.71rem;
      height: 1.61rem;
      background-color: #28292e; // $xf_hei5
      text-align: center;
      line-height: 1.61rem;
      color: #fff; // $white
      font-size: 0.16rem;
      //   border: 1px solid #fff;
    }
    .route_photo {
      width: 2.71rem;
      height: 0.44rem;
      margin: 0.05rem auto;
      background-color: #28292e; // $xf_hei5
      text-align: center;
      line-height: 0.44rem;
      color: #fff; // $white
      font-size: 0.16rem;
      border: 1px solid #ccc;
    }
    .route_photo_list {
      width: 2.8rem;
      height: 6.3rem;
      overflow-y: auto;
      background-color: #28292e; // $xf_hei5
      > div {
        // display: flex;
        // flex-wrap: wrap;
        // align-content: flex-start;
        // justify-content: space-between;
        > div {
          width: 1.34rem;
          height: 1.34rem;
          overflow: hidden;
          float: left;
          margin: 1px;
          img {
            width: 1.34rem;
            height: 1.34rem;
          }
        }
        > div:last-child {
          margin-right: auto;
        }
      }
    }
    .no_photo {
      text-align: center;
      margin-top: 0.2rem;
      color: #c9caca; // $font_color_1
      font-size: 0.14rem;
    }
  }
  .video {
    width: 2.71rem;
    height: auto;
    margin-top: 0.05rem;
    // position: relative;
    // top: 50%;
    // transform: translateY(-50%);
  }
  .videoImg {
    width: 1rem;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chart {
    position: absolute;
    bottom: 0.2rem;
    width: 10rem;
    left: 3.3rem;
    background: rgba(0, 0, 0, 0.7);

    .inner {
      position: relative;
      color: #fff;

      .btn {
        position: absolute;
        left: 50%;
        margin-left: -0.28rem;
        top: -0.28rem;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px 2px 0px 0px;
        width: 0.56rem;
        height: 0.26rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
          transform: rotate(-90deg);
          transition: all 0.3s ease-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .chart-inner {
        overflow: hidden;
        max-height: 0;
        transition: max-height ease-out 0.3s;

        .header {
          padding: 0.1rem;
          padding-right: 8%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .center {
          display: flex;
          height: 2.6rem;
          flex-wrap: nowrap;
          #chart {
            flex: 1;
          }
          .signals {
            flex: 0 0 1.3rem;
            padding-left: 0.2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .signal-item {
              flex: 0 0 40%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              img {
                width: 0.52rem;
                height: 0.18rem;
              }
            }
          }
        }
      }
      .bar {
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        cursor: pointer;

        /deep/ .ivu-slider-bar {
          background-color: #57a3f3;
        }
        /deep/ .ivu-slider-button {
          border: 2px solid #57a3f3;
        }

        .icon {
          width: 0.2rem;
          height: 0.22rem;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .line-bar {
          flex: 1;
          padding: 0 0.2rem;
        }

        .time-left {
          margin-right: 0.2rem;
        }

        .speed {
          cursor: pointer;

          /deep/ .ivu-poptip {
            .ivu-poptip-rel {
              button {
                width: 0.3rem;
                padding: 0;
                background-color: transparent;
                color: #fff;
                font-size: 0.18rem;
              }
            }
            .ivu-poptip-popper {
              min-width: 1rem;
            }
          }
        }
        /deep/ {
          .pop-list {
            display: flex;
            flex-direction: column;
            button {
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    video {
      background-color: #fafafa;
      width: 100%;
      height: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .toggle-modal {
      flex: 1;
      height: 100%;
      background-color: #333; // $hui3
    }
  }
  .dash-board {
    width: 4rem;
    height: 100%;
    background-color: #28292e; // $xf_hei5
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
    .user_info {
      width: 3.92rem;
      height: 1.2rem;
      margin: 0.05rem auto 0;
      padding: 0 0.18rem;
      background-color: #3c3d43; // $xf_hui2
      color: $hui3;
      font-size: 0.13rem;
      border: 1px solid #777; // $xf_hui3
      .user_info_item {
        font-size: 0.16rem;
        color: #c9caca; // $font_color_1
        margin: 0.13rem 0 0.22rem;

        img {
          display: inline;
          //   vertical-align: text-bottom;
          width: 0.16rem;
          margin-right: 0.1rem;
        }
      }
      .head_icon {
        text-align: center;
        display: flex;
        font-size: 0.14rem;
        align-items: center;
        color: #000; // $black
        img {
          width: 0.44rem;
          height: 0.44rem;
          display: block;
          border: 1px solid #707070;
          border-radius: 50%;
        }
        > div {
          text-align: left;
          color: #fff; // $white
          //   p {
          //     // color: #707070;
          //     color: #fff;
          //   }
        }
      }
    }
    .item {
      width: 50%;
      height: 0.51rem;
      background-color: #28292e;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 1px solid #454545; // $xf_hui6
      box-sizing: border-box;
      border-right: 1px solid #454545; // $xf_hui6
      span {
        color: #777; // $xf_hui3
        font-size: 0.12rem;
        p {
          color: #fff;
          //   color: $white;
          font-size: 0.13rem;
        }
      }
    }

    .title {
      width: 100%;
      height: 0.48rem;
      color: #229acc;
      font-size: 0.16rem;
      justify-content: start;
      padding-left: 0.16rem;
    }
    .large {
      width: 100%;
      justify-content: start;
      img {
        margin-left: 0.25rem;
        margin-right: 0.2rem;
      }
    }
  }
  .show {
    .chart-inner {
      max-height: 5rem !important;
      transition: max-height ease-in 0.3s;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 0.1rem 0.2rem;
    }
    .btn {
      i {
        transform: rotate(90deg) !important;
        transition: all 0.3s ease-in;
      }
    }
  }
}
</style>
